import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import Typography, { TypographyProps } from "@mui/material/Typography";

type HighlightTextProps = TypographyProps & {
  text: string;
  value: string;
};

/**
 * Highlights value in a string
 *
 * @param {string} text - containing characters
 * @param {string} value - value to be highlighted
 * @param rest - TypographyProps
 */
const HighlightText = ({ text, value, ...rest }: HighlightTextProps) => {
  try {
    // const matches = match(String(text).toLowerCase(), String(value).toLowerCase().replace(/[^a-z0-9]/gi, " "), {
    const matches = match(String(text), String(value).replace(/[^a-z0-9]/gi, " "), {
      insideWords: true,
      findAllOccurrences: true,
    });
    const parts = parse(text, matches);

    return (
      <Typography component="span" {...rest}>
        {parts.map((part, index) =>
          part.highlight ? (
            <Typography key={index} color="primary" component="span" style={{ fontWeight: "900" }}>
              {part.text}
            </Typography>
            // <span key={index} style={{ fontWeight: "900" }}>{part.text}</span>
          ) : (
            <span key={index}>{part.text}</span>
          )
        )}
      </Typography>
    );
  } catch {
    return <Typography>{text}</Typography>;
  }
};

export default HighlightText;
