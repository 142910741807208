
/* ************************************ NODE ************************************ */
import { ETYPE } from "./entity"


/** Node Type */
const NTYPE = [
    "revision",
    "pow",
    "snl",
    "album",
    "event",
    "group",
    "lecture",
    "post",
    "school",
    "speaker",
    "digital-display",
    "feedback",
    "support",
    "page",
    "call-to-action",
    "janaza",
] as const;
const searchablePosts: typeof NTYPE[number][] = ['post', 'event', 'snl', 'janaza', 'digital-display']

/** user flags stored in user profile */
const user_flags = [
    "like",
    "save",
    "follow",
] as const;
/** node flags stored in node class with only one instance */
const node_flags = [
    "pin",
    "services",
    "featured"
] as const;
/**  */
const event_rsvp_flags = [
    "event-rsvp-going",
    "event-rsvp-maybe",
] as const;

const protected_flags = [
    "spam",
] as const;
export const FLAG = {
    rsvp: event_rsvp_flags,
    user: [...user_flags, ...event_rsvp_flags] as const,
    node: node_flags,
    protected: protected_flags,
    type: [...user_flags, ...node_flags, ...protected_flags, ...event_rsvp_flags] as const,
    fetype: [...NTYPE, "nid", "uid", "cid"] as const
};

/** noi = Nation of Islam */
const DENOMINATION = ["sunni", "shia", "suffi", "ahmadiyya", "nation of islam", "non-denominational"] as const;
export type DenominationType = typeof DENOMINATION[number]
const STATUS = ["published", "pending", "deleted", "draft", "not published", "spam"] as const;

export const NODE = {
    GROUP_NTYPE: ["pow", "school", "group"] as const,
    ETYPE,
    NTYPE,
    DENOMINATION,
    STATUS,
    ApprovalNotRequired: ["support", "feedback"] as const,
    RefreshGroupOnNtype: ["event", "call-to-action"] as typeof NTYPE[number][],
    searchablePosts
};

export type NodeTypes = typeof NODE.NTYPE[number]