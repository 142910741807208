import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';


import "src/core/style.css";
import "src/root/console";
/*
replace import.meta.env.REACT_APP with import.meta.env.VITE
replace process.env with import.meta.env.

*/
// ----------------------------------------------------------------------

import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import AppContextProvider from "src/context/providers";


import { initializeApp } from "src/core/utils/data";
import App from "./App";
// import { registerSW } from "virtual:pwa-register";

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <HelmetProvider>
      <AppContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppContextProvider>
    </HelmetProvider>
  </StrictMode>,
)

initializeApp();


const charAt = `
███╗   ███╗ ██████╗
████╗ ████║ ██║  ██║
██╔████╔██║ ██████╝
██║╚██╔╝██║ ██║   
██║ ╚═╝ ██║ ██║
╚═╝     ╚═╝ ╚═╝
`;
if (import.meta.env.VITE_ENV === "prod") {
  console.info(`%c${charAt}`, "color: #66B2FA");
}

// const updateSW = registerSW({
//   onNeedRefresh() {
//     if (confirm("New content available. Reload?")) {
//       updateSW(true);
//     }
//   },
// });



// const registerServiceWorker = () => {
//   if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//       console.log("loaded")
//       navigator.serviceWorker.register('/sw.js').then(registration => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       }).catch(error => {
//         console.error('Service Worker registration failed:', error);
//       });
//     });
//   }
// }

// setTimeout(() => {
//   registerServiceWorker();
// }, 61000)