import type { DefinedError } from "ajv";
import forEach from "lodash/forEach";
import { FieldsError } from "src/model/shared/errors/FieldsError";
import { PlainObject } from "..";
import ajv from "./ajv";


function combineRequestBodyAndQuery(req: any) {
  if (!req.par) {
    if (req) {
      req.par = {};
      Object.assign(req.par, req.body, req.query);
    }
  }
}

// Ajv will return empty instancePath when require error
const parseErrorSchema = (ajvErrors: DefinedError[]) =>


  ajvErrors.map((error: DefinedError) => {
    if (["oneOf", "anyOf", "if", "then"].includes(error.keyword)) return null;

    if (error.keyword === "required") {
      error.instancePath += `/${error.params.missingProperty}`;
    }
    if (error.keyword === "pattern") {
      error.message = "must match proper pattern";
    }

    const path = error.instancePath.substring(1).replace(/\//g, ".");

    return {
      name: path,
      message: error.message,
    };
  });
// interface IValidatorSchema {
//   $id?: string;
//   type?: string;
//   properties: PlainObject;
// }
/**
 * validate
 * @param RequestOrValues
 * @param schema
 * @returns
 */
export function validate(RequestOrValues: PlainObject, schema: any) {
  let values: PlainObject = RequestOrValues;

  if (RequestOrValues?._events) {
    // express request
    try {
      const url = RequestOrValues?.originalUrl || RequestOrValues?.url || "?";
      const uri = url && url.split("?")[0];
      schema.$id = `${RequestOrValues?.method}-${uri}`;
    } catch {/**/ }
    combineRequestBodyAndQuery(RequestOrValues);
    values = RequestOrValues.par;
  }

  if (!schema.type) schema.type = "object";
  // if (!schema.$id) schema.$id = "empty";

  // values = trimValue(values);
  forEach(schema.properties, (_, key) => {
    if (!values[key]) values[key] = undefined; // if boolean is false, this sets it to undefined, which is set to true useing coerce ajv
    if (!(key in values)) values[key] = undefined;
  });

  const validateSchema = schema.$id
    ? ajv.getSchema(schema.$id) || ajv.compile(schema)
    : ajv.compile(schema);

  const valid = validateSchema(values);

  if (!valid) {
    const error = {
      message: "",
      errors: parseErrorSchema(validateSchema.errors as DefinedError[]),
    };
    try {
      const first = error?.errors[0];
      error.message = `Validator Schema $id:${schema.$id} ${first?.name} ${first?.message}`;
    } catch {/**/ }
    // console.log("error", error);
    // console.log("error===============================> valid:", valid);
    throw new FieldsError(error?.message, error?.errors || error, values);
  }
  return values;
}

export default {
  validate,
};
