

import axios from "src/core/utils/axios";
import { Address } from "src/model/shared/address";
// for troubleshooting you can get browser details https://api.ipgeolocation.io/user-agent?apiKey=API_KEY
export const getUserByIP = (): Promise<Address> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/location/ip`, undefined, undefined, { cache: "never" })
      .then((res) => {

        resolve(new Address({ ...res, formatted_address: res.address }));
      })
      .catch((res) => {
        // console.log("lookupAddress catch", res);
        reject(res);
      });
  });

export const getAddress = (address: string): Promise<Address> =>
  new Promise((resolve, reject) => {
    // console.log("address>>>>", address)
    axios
      .get(`/location/address`, { value: address }, true)
      .then((res) => {
        resolve(new Address(res));
      })
      .catch((res) => {
        // console.log("lookupAddress catch", res);
        reject(res);
      });
  });




// export async function getUserLocationFromGeo(): Promise<Address> {
//   const currentPosition = await getUserCurrentPosition();
//   /** get formatted address */
//   const res = axios.get(`/location/geo`, currentPosition, true)
//   return new Address(res)
// }
