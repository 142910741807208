import { Theme } from "@mui/material/styles";
import { AvatarSizeProps } from "./types";

export const getAvatarSxProps = (theme: Theme, size: AvatarSizeProps) => {
  let sz = theme.spacing(8);
  let additionalProps: any = {};

  if (size === "xs") {
    sz = theme.spacing(4);
  } else if (size === "sm") {
    sz = theme.spacing(5);
  } else if (size === "lg") {
    sz = theme.spacing(11);
  } else if (size === "xl") {
    sz = theme.spacing(15);
  } else if (size === "thumbnail") {
    sz = "120px";
    additionalProps = {
      ...additionalProps,
      // transform: "translateY(-50%)",
      [theme.breakpoints.up("sm")]: { width: "150px", height: "150px" },
      boxShadow: theme.shadows["5"],
    };
  } else if (size === "profile") {
    sz = "130px";
    additionalProps = {
      // transform: "translateY(-50%)",
      [theme.breakpoints.up("sm")]: { width: "150px", height: "150px" },
      //   boxShadow: theme.shadows.xl,
      boxShadow: theme.shadows["20"],
    };
  }

  return { width: sz, height: sz, ...additionalProps };
};
