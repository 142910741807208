const PORT = import.meta.env.VITE_API_PORT ? `:${import.meta.env.VITE_API_PORT}` : "";
export const AXIOS = {
  url: `${window.location.protocol}//${window.location.hostname}${PORT}/api`,
  refreshUrl: "/auth/token",
  headers: {
    // language: navigator.language,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    'User-Referrer': window.location.hostname
  },
}