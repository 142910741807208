export default function Divider() {

  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1.5,
        }
      },
    },
  };
}
