import axios from "axios";
import { AXIOS } from "src/config/axios";


const axiosInstance = axios.create({
    baseURL: AXIOS.url,
    timeout: 60000,
    responseType: "json",
    headers: AXIOS.headers,
});
// (async () => {
//     // const s = window.location.hostname;
//     // const secret = await encryptJSON(s, s)
//     // const jwt = sign(secret, s, { expires: "30d" })
//     // // console.log(btoa(JSON.stringify(_)))
//     // // console.log(" window.location", window.location)
//     // axiosInstance.defaults.headers._ = jwt
// })()
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // eslint-disable-next-line prefer-const
        let { code, message, name, ...rest } = error?.response?.data || error
        if (!error?.response?.data) rest = {}

        // if (message === "auth/id-token-expired") {
        //     delete axiosInstance.defaults.headers.Authorization;
        // }
        return Promise.reject({ code, message, name, ...rest })
    }

);
export default axiosInstance;