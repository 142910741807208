import reject from "lodash-es/reject";
import set from "lodash-es/set";

import { action, makeObservable, observable, } from "mobx";


import { PlainObject } from "src/model";


import { getItemAsync, setItemAsync } from "src/core/utils/storage";
import { FlagResponseProps } from "src/model/shared/flag/flag-types";
import { _UP_ } from "../types";
import { getUUID } from "../utils/uuid";
import { userConfig } from "src/config/user-config";
import { getUserByIP } from "src/utils/location";
import axios from "src/core/utils/axios";
import axiosInstance from "src/core/utils/axios/instance";
import { Address } from "../../../model/shared/address";
import { User } from "../../../model/shared/user";
import { UserMembershipProps, UserProps } from "../../../model/shared/user/types";
import { UserApi } from "../../../model/shared/user/user-api";


/** big problems
 * 
 * 
 * import { Address } from "src/model/shared/address"; // 4.3 > 3.6
 * 
 */
let FETCHING_IP_ADDRESS = false
const anonymous = userConfig.data;
const defaultLocation = new Address(userConfig.location);


const UUID = getUUID();

type UserStoreProps = {
  clients: number;
  token?: string; // needed for sockets
  isAuthenticated: boolean;

  location: Address;
  user: User;
  group: {
    add: (m: UserMembershipProps) => void,
    delete: (eid: number) => void,
  }
}

class UserStore implements UserStoreProps {
  clients = 0

  token?: string = undefined;

  isAuthenticated = false;

  location: Address = defaultLocation

  /** for anonymouse users */
  user = new User(anonymous);


  /** props is used to share data without updating rest of compononent, example is events page to update dates without refreshing rest of the page */
  props: any = {}

  clear = async () => {

    this.isAuthenticated = false;
    this.token = undefined;

    this.user = new User(anonymous);
    this.location = defaultLocation
    this.update.storage();
    axiosInstance.defaults.headers.Authorization = ``;
  };

  set = (_user: UserProps, token?: string) => {
    this.user = new User(_user);
    // this.user.notifications.map(n => NotificationsStore.add(n as any))

    if (axios.cache?.clear) {
      axios.cache.clear()
    }
    if (token) {
      if (!this.isAuthenticated && Boolean(token)) {
        /** loaded user activity stream */
        // NotificationsStore.db.load();
      }
      this.update.token(token);
    }

    this.update.storage();

  };

  update = {
    token: (t?: string) => {
      this.token = t;

      this.isAuthenticated = Boolean(t);
    },
    storage: () => {
      const ls: any = {
        location: this.location,
      }
      // if (this.isAuthenticated) {// we need to save anonymous user data as well
      ls.user = this.user

      setItemAsync(_UP_, JSON.parse(JSON.stringify(ls)), UUID)
    },
    location: (newAddress: Address) => {
      const na = Address.valid(newAddress)
      if (na) {
        this.location = na;
        this.update.storage();
      }
    },
    user: async (newUser: Partial<UserProps>) => {

      const nu = new User(this.user);
      const changes: { key: string, value: any }[] = nu.update(newUser);
      if (changes && changes.length > 0) {
        const newUserChanges = {}
        for (const c of changes) {
          set(newUserChanges, c.key, c.value);
        }
        await this.db.update(newUserChanges);
        this.user = nu;

        this.update.storage();
      }

    },
    props: (_props_: PlainObject) => {
      this.props = { ...this.props, ..._props_ }
    },
  }

  db = {

    update: async (obj: PlainObject) => {
      if (!this.isAuthenticated) {
        return;
      }
      const o = new UserApi(this.user);
      o.update(obj)

      try {
        await axios.post("/user", o)
      } catch {/** */

        // console.log("update user api err", err);
      }
    }
  }



  group = {
    add: (m: UserMembershipProps) => {
      this.user.membership.push(m);
      const newUser = new User(this.user);
      this.set(newUser)
    },
    delete: (eid: number) => {
      this.user.membership = reject(this.user.membership, { eid })
      const newUser = new User(this.user);
      this.set(newUser)
    },
  }

  flag = {
    add: (flag: FlagResponseProps) => {
      this.user.flags.push(flag)
      this.set(this.user)
    },
    remove: (flag: FlagResponseProps) => {
      this.user.flags = reject(this.user.flags, { eid: flag.eid, type: flag.type, fetype: flag.fetype });
      this.set(this.user)
    }
  }

  async getIPAddress() {
    if (FETCHING_IP_ADDRESS) return;
    FETCHING_IP_ADDRESS = true
    const { lat, lng } = this.location || {};
    if (lat === defaultLocation.lat && lng === defaultLocation.lng) {
      try {
        const address = await getUserByIP()

        this.update.location(address)
      } catch {/** */ }
    }
  }


  loadData = async () => {

    const initalStorage: any = await getItemAsync(_UP_, {}, UUID)
    const { user, location } = initalStorage || {}

    if (location) {
      const loc = Address.valid(location);
      this.location = loc || defaultLocation
    }

    if (user) {
      // console.log("user111", user)
      this.user = new User(user)
    }



  }


  constructor() {
    this.loadData();

    makeObservable(this, {
      getIPAddress: action,
      isAuthenticated: observable,

      clients: observable,
      user: observable,
      location: observable,
      props: observable,


      set: action,

    });
  }
}
const u = new UserStore();
export default u;

// export { UserStore };
