import Ajv from "ajv";

import ajvErrors from "ajv-errors";
import ajvFormats from "ajv-formats";
import ajvKeywords from "ajv-keywords";
import set from "lodash/set";
import { PlainObject } from "../index";
import normalizeUrl from "./modules/normalize-url";

const ajv = new Ajv({
  allErrors: true, // false: first error only, true: return all errors
  useDefaults: true, // useDefaults - to assign defaults from the schema to the validated data properties.
  coerceTypes: true, // coerceTypes - to change data type, when possible, to match the type(s) in the schema.
  removeAdditional: true, // removeAdditional - to remove properties not defined in the schema object.
  strictTuples: false, // annoying warning saying minItems or maxItems not specified when they are
  // jsonPointers: true,
});


/** we need to use password from schema, but need to test regex in other languages */
ajvFormats(ajv, [
  // "date",
  // "regex", 
  "uri",
  "uri-reference",
  "email",
  "uuid"
]);

ajvErrors(ajv);
ajvKeywords(ajv, ["transform"]);

ajv.addKeyword({
  keyword: "isNotEmpty",
  type: "string",
  // schemaType: "boolean",
  validate(_: PlainObject, data: string) {
    console.error("data >>>>>>>>>> ", data);

    return typeof data === "string" && data?.trim() !== "";
  },
  // errors: false,
});
ajv.addKeyword({
  keyword: "fileSize",
  type: "string",
  validate(schema: any, value: any) {
    return Buffer.from(value, "base64").length / (1024 * 1024) < schema;
  },
  error: {
    message: "file is too big",
  },
});

function normalizeURL(url: string) {
  // setting return to null will override the actual url value to null
  try {
    if (!url || String(url) === "") {
      return undefined;
    }
    if (!url.includes(".")) {
      return null;
      // throw new Error("invalid uri");
    }


    new URL(url);
    const u = normalizeUrl(url?.trim(), {
      stripHash: true,
    });

    return u;
  } catch (err) {
    // throw new Error("invalid uri");
    return null;
  }
}

ajv.addKeyword({
  // alters current value
  keyword: "normalizeURL",
  modifying: true,
  schema: false,
  // valid: true,
  validate(data: string, dataPath: any) {
    const arr = dataPath.instancePath.split("/").slice(1).join(".");
    const value = normalizeURL(data);
    if (value === null) {
      return false;
    }
    set(dataPath.rootData, arr, value);
    return true;
  },
  error: {
    message: "invalid url",
  },
});
// ajv.addKeyword({
//   //alters current value
//   keyword: "onlyNumbers",
//   modifying: true,
//   schema: false,
//   validate: function (data: string, dataPath: any) {
//     let arr = dataPath.instancePath.split("/").slice(1).join(".");
//     set(dataPath.rootData, arr, String(data).replace(/[^0-9]/g, ""));

//     return true;
//   },
//   error: {
//     message: "invalid url",
//   },
// });




ajv.addFormat("date-time", (str: string): any => {
  /*
  matches both below formats: /(\d{4})-(\d{2})-(\d{2})\s(([0-1]?[0-9])|([2][0-3])):([0-5]?[0-9])(:([0-5]?[0-9]))?$/g
  2029-05-22 18:00  2029-05-22 18:00:00

    matches both below formats: /(\d{4})-(\d{2})-(\d{2})[\sT]?(([0-1]?[0-9])|([2][0-3])):([0-5]?[0-9])(:([0-5]?[0-9]))?([\sZ])?$/g
    2029-05-22 18:00
    2029-05-22 18:00:00
    2029-05-22T18:00:00
    2029-05-22T18:00:00Z


    matches both below formats: /(\d{4})-(\d{2})-(\d{2})[T]?(([0-1]?[0-9])|([2][0-3])):([0-5]?[0-9])(:([0-5]?[0-9]))?([\sZ])?$/g
    2029-05-22T18:00
    2029-05-22T18:00:00
    2029-05-22T18:00:00Z
  */
  return str.match(
    /(\d{4})-(\d{2})-(\d{2})[T]?(([0-1]?[0-9])|([2][0-3])):([0-5]?[0-9])(:([0-5]?[0-9]))?([\sZ])?$/g
  );


});
ajv.addFormat("latitude", (str: string): any => {
  /* lat: -90 to 90, lng: -180 to 180
   */
  return String(str).match(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,20})?$/);
});
ajv.addFormat("date", (str: string): any => {
  /*
  HH:MM 24-hour format, optional leading 0
  /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  */

  return str.match(/^\d{4}-\d{2}-\d{2}$/);
});
ajv.addFormat("time", (str: string): any => {
  /*
  HH:MM 24-hour format, optional leading 0
  /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  */

  return str.match(/^(?:[0-2]\d:[0-5]\d(:[0-5]\d)?|23:59:60)$/);
});
export default ajv;
